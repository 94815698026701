import React from 'react'
import UnderConstruction from "../components/UnderConstruction";

const Cart = () => {
	return (
		<UnderConstruction />
	)
}

export default Cart
